import { httpGET, httpPOST, httpDELETE } from "./httpHelpers";

// Read the latest top ten interactions
export async function httpGetTopTenInteractions(
  fromDate,
  toDate,
  siteIds,
  frames,
  limit,
  offset,
  unscoped
) {
  return httpGET(
    `/analytics/topten/interactions?fromDate=${encodeURIComponent(
      fromDate
    )}&toDate=${encodeURIComponent(toDate)}&sites=${encodeURIComponent(
      (siteIds || []).join(",")
    )}&frames=${encodeURIComponent(
      (frames || []).join(",")
    )}&limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(
      offset
    )}&unscoped=${unscoped}`
  );
}

// Delete filtered top ten interactions
export async function httpDeleteTopTenInteractions(
  fromDate,
  toDate,
  siteIds,
  frames
) {
  return httpDELETE(
    `/analytics/topten/interactions?fromDate=${encodeURIComponent(
      fromDate
    )}&toDate=${encodeURIComponent(toDate)}&sites=${encodeURIComponent(
      (siteIds || []).join(",")
    )}&frames=${encodeURIComponent((frames || []).join(","))}`
  );
}

// Restore filtered top ten interactions
export async function httpRestoreTopTenInteractions(
  fromDate,
  toDate,
  siteIds,
  frames
) {
  return httpPOST(
    `/analytics/topten/interactions?fromDate=${encodeURIComponent(
      fromDate
    )}&toDate=${encodeURIComponent(toDate)}&sites=${encodeURIComponent(
      (siteIds || []).join(",")
    )}&frames=${encodeURIComponent((frames || []).join(","))}`
  );
}

// Fetch a list of unique Top Ten sites
export async function httpGetTopTenSites() {
  return httpGET(`/analytics/topten/sites`);
}

// Update Top Ten site mapping
export async function httpUpdateTopTenSites(payload) {
  return httpPOST(`/analytics/topten/sites`, payload);
}
