import { createContext, useContext, useMemo, useState } from "react";
import {
  httpRegistrationRequest,
  httpPasswordResetRequest,
  httpResetPassword,
  httpLogin,
  httpLogout,
} from "./authapi";
import {
  httpGetUsers,
  httpResetUserProfile,
  httpRevokeUserToken,
  httpUpdateUsers,
  httpGetUserPermissions,
  httpUpdateUserPermissions,
  httpReinstateUserPermission,
  httpGetUserRoles,
  httpUpdateUserRoles,
  httpReinstateUserRole,
} from "./userapi";
import { useAppState } from "../AppStateProvider/AppStateProvider";
import { useCallback } from "react";
import {
  httpGenerateDeviceToken,
  httpGetDevices,
  httpReinstateDevice,
  httpRevokeDeviceToken,
  httpUpdateDevices,
} from "./deviceapi";
import { useNavigate } from "react-router-dom";
import {
  httpFlushBloombergIndices,
  httpGetBloombergIndices,
  httpGetBloombergNews,
  httpGetInvestecFocusFeeds,
} from "./feedsapi";
import {
  httpGenerateToken,
  httpGetTokenPermissions,
  httpGetTokenUsage,
  httpGetTokens,
  httpReinstateToken,
  httpReinstateTokenPermission,
  httpRevokeToken,
  httpUpdateTokenPermissions,
  httpUpdateTokens,
} from "./tokenapi";
import { httpGetStorageObject } from "./storageapi";
import { httpDeployMtnHome, httpDeployTopTen } from "./deploymentapi";
import {
  httpDeleteTopTenInteractions,
  httpGetTopTenInteractions,
  httpGetTopTenSites,
  httpRestoreTopTenInteractions,
  httpUpdateTopTenSites,
} from "./analyticsapi";

// Create an auth context for all routes
const ApiContext = createContext();

// Auth provider helper function
export function ApiProvider({ children, data }) {
  // Portal state object
  const [sessionState, setSessionState] = useState(data);

  // Get notification context
  const { setToast } = useAppState();

  // Router navigation
  const navigate = useNavigate();

  // A function that wraps API calls in order to process errors and responses
  const apiWrapper = useCallback(
    async (handler, ...args) => {
      // Execute the call - returning the decoded json response
      const res = await handler(...args);

      console.log(`${res.status} - ${res.ok ? "OK" : "NOT OK"}`);

      const contentType = res.headers.get("Content-Type");

      // Return decoded JSON if the call was successful
      if (res.ok && contentType === "application/json") {
        return res.json();
      }

      // Return the blob if necessary
      if (res.ok && contentType === "application/zip") {
        return res.blob();
      }

      // Get the error string
      const errorStr = await res.text();

      // Capitalise the first letter of the error string
      const formattedErr = `${res.status}: ${(errorStr.charAt(0) || "").toUpperCase() + errorStr.slice(1)
        }`;

      // Show a toast
      setToast({
        message: formattedErr,
        type: "error", // success / error / warning / info / default
      });

      // Redirect to dashboard if...
      // -status is 401 --- unauthorised
      // -status is 403 --- forbidden
      if (res.status === 401 || res.status === 403)
        return navigate("/dashbaord", { replace: true });

      // Throw an error otherwise
      throw new Error(formattedErr);
    },
    [setToast, navigate]
  );

  // App state helpers
  const appState = useMemo(() => {
    return {
      user: sessionState?.User,
      roles: sessionState?.Roles || [],
      permissions: sessionState?.Permissions || { Api: [], User: [] },
      routes: sessionState?.Routes || [],
    };
  }, [sessionState]);

  // Auth API
  const authApi = useMemo(
    () => ({
      registrationRequest: (...args) =>
        apiWrapper(httpRegistrationRequest, ...args),
      passwordResetRequest: (...args) =>
        apiWrapper(httpPasswordResetRequest, ...args),
      passwordReset: (...args) => apiWrapper(httpResetPassword, ...args),
      login: (...args) => apiWrapper(httpLogin, ...args),
      logout: (...args) => apiWrapper(httpLogout, ...args),
    }),
    [apiWrapper]
  );

  // User API
  const userApi = useMemo(
    () => ({
      get: (...args) => apiWrapper(httpGetUsers, ...args),
      update: (...args) => apiWrapper(httpUpdateUsers, ...args),
      revokeToken: (...args) => apiWrapper(httpRevokeUserToken, ...args),
      resetProfile: (...args) => apiWrapper(httpResetUserProfile, ...args),
    }),
    [apiWrapper]
  );

  // User roles API
  const userRoleApi = useMemo(
    () => ({
      get: (...args) => apiWrapper(httpGetUserRoles, ...args),
      update: (...args) => apiWrapper(httpUpdateUserRoles, ...args),
      reinstate: (...args) => apiWrapper(httpReinstateUserRole, ...args),
    }),
    [apiWrapper]
  );

  // User permissions API
  const userPermApi = useMemo(
    () => ({
      get: (...args) => apiWrapper(httpGetUserPermissions, ...args),
      update: (...args) => apiWrapper(httpUpdateUserPermissions, ...args),
      reinstate: (...args) => apiWrapper(httpReinstateUserPermission, ...args),
    }),
    [apiWrapper]
  );

  // Device API
  const deviceApi = useMemo(
    () => ({
      get: (...args) => apiWrapper(httpGetDevices, ...args),
      update: (...args) => apiWrapper(httpUpdateDevices, ...args),
      revokeDeviceToken: (...args) =>
        apiWrapper(httpRevokeDeviceToken, ...args),
      generateDeviceToken: (...args) =>
        apiWrapper(httpGenerateDeviceToken, ...args),
      reinstateDevice: (...args) => apiWrapper(httpReinstateDevice, ...args),
    }),
    [apiWrapper]
  );

  // Token API
  const tokenApi = useMemo(
    () => ({
      usage: (...args) => apiWrapper(httpGetTokenUsage, ...args),
      get: (...args) => apiWrapper(httpGetTokens, ...args),
      update: (...args) => apiWrapper(httpUpdateTokens, ...args),
      revoke: (...args) => apiWrapper(httpRevokeToken, ...args),
      reinstate: (...args) => apiWrapper(httpReinstateToken, ...args),
      generate: (...args) => apiWrapper(httpGenerateToken, ...args),
    }),
    [apiWrapper]
  );

  // Token API
  const tokenPermApi = useMemo(
    () => ({
      get: (...args) => apiWrapper(httpGetTokenPermissions, ...args),
      update: (...args) => apiWrapper(httpUpdateTokenPermissions, ...args),
      reinstate: (...args) => apiWrapper(httpReinstateTokenPermission, ...args),
    }),
    [apiWrapper]
  );

  // Feeds API
  const feedsApi = useMemo(
    () => ({
      bloombergIndices: (...args) =>
        apiWrapper(httpGetBloombergIndices, ...args),
      flushBloombergIndices: (...args) =>
        apiWrapper(httpFlushBloombergIndices, ...args),
      bloombergNews: (...args) => apiWrapper(httpGetBloombergNews, ...args),
      investecFocusFeeds: (...args) =>
        apiWrapper(httpGetInvestecFocusFeeds, ...args),
    }),
    [apiWrapper]
  );

  // Storage API
  const storageApi = useMemo(
    () => ({
      get: (...args) => apiWrapper(httpGetStorageObject, ...args),
    }),
    [apiWrapper]
  );

  // Deployment API
  const deploymentApi = useMemo(
    () => ({
      topTen: (...args) => apiWrapper(httpDeployTopTen, ...args),
      mtnHome: (...args) => apiWrapper(httpDeployMtnHome, ...args),
    }),
    [apiWrapper]
  );

  // Analytics API
  const analyticsApi = useMemo(
    () => ({
      topTenInteractions: (...args) =>
        apiWrapper(httpGetTopTenInteractions, ...args),
      deleteTopTenInteractions: (...args) =>
        apiWrapper(httpDeleteTopTenInteractions, ...args),
      restoreTopTenInteractions: (...args) =>
        apiWrapper(httpRestoreTopTenInteractions, ...args),
      topTenSites: (...args) => apiWrapper(httpGetTopTenSites, ...args),
      updateTopTenSites: (...args) =>
        apiWrapper(httpUpdateTopTenSites, ...args),
    }),
    [apiWrapper]
  );

  // User data and methods
  const value = useMemo(
    () => ({
      setSessionState,
      appState,
      authApi,
      userApi,
      userRoleApi,
      userPermApi,
      deviceApi,
      tokenApi,
      tokenPermApi,
      feedsApi,
      storageApi,
      deploymentApi,
      analyticsApi,
    }),
    [
      appState,
      authApi,
      userApi,
      userRoleApi,
      userPermApi,
      deviceApi,
      tokenApi,
      tokenPermApi,
      feedsApi,
      storageApi,
      deploymentApi,
      analyticsApi,
    ]
  );

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

// Custom hook to provide access to auth details
export function useApi() {
  return useContext(ApiContext);
}
